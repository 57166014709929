import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Block, BlockComponentProps } from 'strapi/types/utils';

const componentMapping: {
  [K in Block['__component']]: React.ComponentType<BlockComponentProps<K>>;
} = {
  'marketing-website.vertical-padding': dynamic(
    () => import('strapi/components/VerticalPadding')
  ),
  'marketing-website.text-with-video': dynamic(
    () => import('strapi/components/TextWithVideo')
  ),
  'marketing-website.text-with-image': dynamic(
    () => import('strapi/components/TextWithImage')
  ),
  'marketing-website.text-with-image-list': dynamic(
    () => import('strapi/components/TextWithImageAndList')
  ),
  'marketing-website.text-modal': dynamic(
    () => import('strapi/components/TextModal')
  ),
  'marketing-website.text-block': dynamic(
    () => import('strapi/components/TextBlock')
  ),
  'marketing-website.section-header': dynamic(
    () => import('strapi/components/SectionHeader')
  ),
  'marketing-website.review-carousel': dynamic(
    () => import('strapi/components/ReviewCarousel')
  ),
  'marketing-website.private-quote-widget': dynamic(
    () => import('strapi/components/PrivateQuoteWidget')
  ),
  'marketing-website.policy-link-section': dynamic(
    () => import('strapi/components/PolicyLinkSection')
  ),
  'marketing-website.info-card-section': dynamic(
    () => import('strapi/components/InfoCardSection')
  ),
  'marketing-website.hero-banner': dynamic(
    () => import('strapi/components/HeroBanner')
  ),
  'marketing-website.faq-section': dynamic(
    () => import('strapi/components/FaqSection')
  ),
  'marketing-website.cta-banner': dynamic(
    () => import('strapi/components/CtaBanner')
  ),
  'marketing-website.calculator': dynamic(
    () => import('strapi/components/Calculator')
  ),
  'marketing-website.addon-section': dynamic(
    () => import('strapi/components/AddonSection')
  ),
  'marketing-website.table': dynamic(() => import('strapi/components/Table')),
  'marketing-website.button-card-section': dynamic(
    () => import('strapi/components/ButtonCardSection')
  ),
  'marketing-website.company-health-calculator': dynamic(
    () => import('strapi/components/CompanyHealthCalculator')
  ),
} as const;

const getBlockComponent = ({
  block,
  onError,
}: {
  block: Block;
  onError: (message: string) => JSX.Element;
}) => {
  const BlockComponent = componentMapping[block.__component];

  if (!BlockComponent) {
    throw Error(`Component of type ${block.__component} was not defined`);
  }

  // TODO: remove the type assertion here. I could not find a way to make the typechecks pass
  // TODO: also fix ESlint in [EPIC-1083]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <BlockComponent block={block as any} onError={onError} />;
};

const ComponentManager = ({ blocks }: { blocks: Block[] }) => {
  const { isPreview } = useRouter();

  const handleError = (key: string, message: string) => {
    if (isPreview) {
      const componentName = key.replace('marketing-website.', '');
      return (
        <div className="p-body bg-red-700 p40 my16">
          {`${componentName}: ${message}`}
        </div>
      );
    }
    throw new Error(message);
  };

  return (
    <div>
      {blocks.map((block) => (
        <div key={`${block.__component}-${block.id}`}>
          {getBlockComponent({
            block,
            onError: (message: string) =>
              handleError(block.__component, message),
          })}
        </div>
      ))}
    </div>
  );
};

export default ComponentManager;
